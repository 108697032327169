import { useTranslate } from "@refinedev/core";
import { Button, Flex, Typography } from "antd";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useTeam from "../../hooks/useTeam";
import useTeamFn from "../../hooks/useTeamFn";

type Props = {
  hasInvitations?: boolean;
};
export function AccountSetupConfetti({ hasInvitations }: Props) {
  const navigate = useNavigate();
  // const jsConfetti = useMemo(() => new JSConfetti(), []);
  const translate = useTranslate();
  // setTimeout(() => {
  //   jsConfetti.addConfetti();
  // }, 500);
  const { allTeams, personalAccount } = useTeam();
  const hasTeam = useMemo(
    () => allTeams?.some((team) => !team.personal_account),
    [allTeams]
  );

  const { createTeam } = useTeamFn();

  async function handleTeamCreate() {
    await createTeam({
      name: personalAccount?.metadata?.details?.company_name,
    });
    navigate("/");
  }
  return (
    <Flex vertical align="center" justify="center" style={{ height: "100vh" }}>
      <div style={{ maxWidth: 500, textAlign: "center" }}>
        <Typography.Title level={2}>
          {translate("account.account_creation_success_title")}
        </Typography.Title>
        <Typography.Title level={5}>
          {translate("account.account_creation_success")}
        </Typography.Title>
      </div>
      <Button
        type="primary"
        onClick={() =>
          hasInvitations
            ? navigate("/team/invitations")
            : hasTeam
            ? navigate("/team/create")
            : handleTeamCreate()
        }
      >
        {translate(
          `account.account_${
            hasInvitations ? "invitation" : "continue"
          }_success_button`
        )}

        {/* {translate("account.account_creation_success_button")} */}
      </Button>
    </Flex>
  );
}
