import { useModalForm } from "@refinedev/antd";
import { useList, useTranslate } from "@refinedev/core";
import { Button, Col, Form, Modal, Row } from "antd";
import { useState } from "react";
import useTeam from "../../hooks/useTeam";
import { getContactFormatedName } from "../../utility/contactName";
import AddressAutoselect from "../common/AddressAutoselect";
import ContactAutoSelect from "../contacts/ContactAutoSelect";
import InvoiceTable from "../shop/product-items";

export default function CreateOrEditInvoice({
  open,
  invoice,
  onClose,
  contact,
}: {
  open?: boolean;
  invoice?: any;
  onClose: () => void;
  contact?: any;
}) {
  const translate = useTranslate();
  const { currentTeam } = useTeam();

  const { formProps, modalProps, form } = useModalForm({
    resource: "invoices",
    action: invoice?.id ? "edit" : "create",
    id: invoice?.id,
    onMutationSuccess: () => {
      onClose();
    },
    redirect: contact ? false : "list",
  });

  const [lineItems, setLineItems] = useState();
  const [total, setTotal] = useState<any>();
  const [selectedContactId, setSelectedContactId] = useState(
    contact?.id || null
  );
  const [selectedContact, setSelectedContact] = useState<any>(contact || null);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );
  const { data: testIntegration } = useList({
    resource: "integration_accounts",
    filters: [
      { field: "account_type", operator: "eq", value: "lexware_partner" },
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const handleCreateInvoice = async () => {
    await form?.validateFields();
    const values: any = form?.getFieldsValue();
    formProps.onFinish?.({
      account_address: selectedAddress,
      account_country: "DE",
      account_name: currentTeam?.name,
      account_tax_id: "123",
      line_items: lineItems,
      amount: (total?.total * 100).toFixed(),
      amount_paid: 0,
      amount_shipping: 0,
      account: currentTeam?.account_id,
      contact_email: selectedContact?.email,
      contact_name: getContactFormatedName(
        selectedContact?.company_name,
        selectedContact?.firstname,
        selectedContact?.lastname,
        selectedContact?.email
      ),
      date: new Date(),
      status: "unfinished",
      contact: selectedContact?.id,
      contact_address: selectedAddress,
    });
  };

  const handleSaveAsDraft = async () => {
    await form?.validateFields();
    const values: any = form?.getFieldsValue();
    await formProps.onFinish?.({
      ...values,
      account_address: selectedAddress,
      account_country: "DE",
      account_name: currentTeam?.name,
      account_tax_id: "123",
      line_items: lineItems,
      amount: (total?.total * 100).toFixed(),
      amount_paid: 0,
      amount_shipping: 0,
      account: currentTeam?.account_id,
      contact_email: selectedContact?.email,
      contact_name: getContactFormatedName(
        selectedContact?.company_name,
        selectedContact?.firstname,
        selectedContact?.lastname,
        selectedContact?.email
      ),
      date: new Date(),
      status: "unfinished",
      contact: selectedContact?.id,
      contact_address: selectedAddress,
    });
  };

  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={onClose}
      centered
      width={1050}
      title={
        invoice?.id
          ? translate("settings.invoices.edit_invoice", "Edit Invoice")
          : translate("settings.invoices.create_invoice", "Create Invoice")
      }
      footer={[
        <Button key="cancel" onClick={onClose}>
          {translate("settings.invoices.cancel", "Cancel")}
        </Button>,
        <Button key="save-as-draft" onClick={handleSaveAsDraft} type="default">
          {translate("settings.invoices.save_as_draft", "Save as Draft")}
        </Button>,
        <Button
          key="create-invoice"
          disabled={!testIntegration || testIntegration?.data.length <= 0}
          onClick={handleCreateInvoice}
          type="primary"
        >
          {translate("settings.invoices.create_invoice", "Create Invoice")}
        </Button>,
      ]}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            {contact ? (
              <>
                <Form.Item>
                  <AddressAutoselect
                    contactId={selectedContactId}
                    name="contact_address"
                    label={translate("contacts.address")}
                    onSelect={(value) => {
                      setSelectedAddressId(value);
                    }}
                    getAddress={(value) => setSelectedAddress(value)}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item name="contact">
                  <ContactAutoSelect
                    label={translate("settings.invoices.fields.enter_customer")}
                    required={true}
                    name="contact"
                    onSelect={(value) => {
                      setSelectedContactId(value);
                      setSelectedAddressId(null);
                    }}
                    getContact={(value) => setSelectedContact(value)}
                  />
                </Form.Item>
                {selectedContactId && (
                  <Form.Item>
                    <AddressAutoselect
                      contactId={selectedContactId}
                      name="contact_address"
                      label={translate("contacts.address")}
                      onSelect={(value) => {
                        setSelectedAddressId(value);
                      }}
                      getAddress={(value) => {
                        console.log("value", value);
                        if (
                          !value.country ||
                          ~[2, 3].includes(value.country.length)
                        ) {
                          formProps.form?.setFields([
                            {
                              name: "contact_address",
                              errors: [
                                "Please set or reset the country on the address.",
                              ],
                            },
                          ]);
                        }
                        setSelectedAddress(value);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Col>
          {selectedAddressId && (
            <InvoiceTable
              onProductsChange={(value) => setLineItems(value)}
              onTotalChange={(value) => setTotal(value)}
            />
          )}
        </Row>
      </Form>
    </Modal>
  );
}
