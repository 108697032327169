import { useModalForm } from "@refinedev/antd";
import { useTable, useTranslate } from "@refinedev/core";
import { Col, ColorPicker, Form, Input, Modal, Row } from "antd";
import { useUser } from "../../../contexts/ContextProvider";

type Props = {
  id?: string | number;
  open: boolean;
  onClose: (id?: any) => void;
};

export default function CreateOrUpdate({ open, onClose, id }: Props) {
  const { modalProps, formProps } = useModalForm({
    resource: "tags",
    action: id ? "edit" : "create",
    redirect: false,
    id: id,
    onMutationSuccess: (data) => {
      onClose(data.data.id);
    },
  });
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { tableQueryResult, setFilters } = useTable({
    resource: "tags",
    pagination: { current: 1, pageSize: 1 },
    syncWithLocation: false,
  });

  const uniqueNameValidator = async (_: any, value: string) => {
    if (!value) return Promise.resolve();

    setFilters([
      {
        field: "name",
        operator: "eq",
        value,
      },
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ]);

    const result = await tableQueryResult.refetch();
    const data = result.data?.data ?? [];

    // If we are creating a new tag (no id), then any existing record invalidates it.
    // If we are editing an existing tag (id is defined), then an existing record with a different id invalidates it.
    if (data.length > 0 && (id === undefined || data[0].id !== id)) {
      return Promise.reject(new Error(translate("tags.errors.unique")));
    }

    return Promise.resolve();
  };

  return (
    <Modal
      {...modalProps}
      open={open}
      style={{ maxWidth: 600, zIndex: 1000000 }}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        onClose();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) =>
          formProps.onFinish &&
          formProps?.onFinish({
            ...values,
            account: currentTeam?.account_id,
          })
        }
      >
        <Row gutter={20}>
          <Col span={20} order={0}>
            <Form.Item
              name={"name"}
              label={translate("tags.title")}
              rules={[
                {
                  required: true,
                  message: translate(
                    "tags.errors.required",
                    "Name is required"
                  ),
                },
                { validator: uniqueNameValidator },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} order={1}>
            <Form.Item name="color" label={translate("tags.color")}>
              <ColorPicker
                onChange={(color) => {
                  formProps?.form?.setFieldValue("color", color.toHexString());
                }}
                defaultValue={
                  formProps?.form?.getFieldValue("color") ?? "#d1d5db"
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
