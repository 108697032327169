import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { DeleteButton, useForm } from "@refinedev/antd";
import { useList, useTranslate } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";
import useAppsList from "./appsList";

import dayjs from "dayjs"; // Zentrales dynamisches Modal
import AddConnectionModal from "./AddConnectionModal";
import AddSmtpConnectionModal from "./smtp/AddSmtpConnectionModal";

export default function IntegrationsList() {
  type AppType = {
    id: string;
    title: string;
    icon: JSX.Element;
    comingSoon?: boolean;
    limit?: number;
  };

  interface Account {
    id: string;
    created_at: string;
    account_type: string;
    credentials: {
      api_key?: string;
      iban?: string;
      bic?: string;
    };
    email_sender_addresses: { email: string }[];
  }

  const [selectedApp, setSelectedApp] = useState<AppType | null>(null);
  const [activeTab, setActiveTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [emailValidationStatus, setEmailValidationStatus] = useState({});
  const [isAddConnectionModalVisible, setAddConnectionModalVisible] =
    useState(false);
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const { form, formProps } = useForm();
  const { data, isLoading, refetch } = useList({
    resource: "integration_accounts",
    meta: { select: "*, email_sender_addresses(*)" },
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const products = useMemo(() => data?.data ?? [], [data?.data]);
  const groupedProducts = useMemo(() => {
    return products.reduce((acc, product) => {
      const key = product.account_type;
      if (!acc[key]) acc[key] = [];
      acc[key].push(product);
      return acc;
    }, {});
  }, [products]);

  const appsList = useAppsList();

  useEffect(() => {
    if (selectedApp) setDetailsModalVisible(true);
  }, [selectedApp]);

  const handleSearch = debounce(
    (event) => setSearchTerm(event.target.value.toLowerCase()),
    300
  );

  const handleCardClick = (app) => setSelectedApp(app);

  const getFilteredApps = () => {
    const filteredApps = appsList.filter((app) => {
      return (
        app.title.toLowerCase().includes(searchTerm) ||
        app.description.toLowerCase().includes(searchTerm)
      );
    });
    if (activeTab === "connected")
      return filteredApps.filter((app) => groupedProducts[app.id]);
    return filteredApps.filter((app) => app.category.includes(activeTab));
  };

  const renderDetailsModal = () => {
    const connectedAccounts = selectedApp
      ? groupedProducts[selectedApp.id]
      : [];
    const canAddMoreConnections =
      selectedApp?.limit == null ||
      (connectedAccounts?.length ?? 0) < selectedApp.limit;

    return (
      <Modal
        width={"60%"}
        style={{ overflowY: "auto", padding: 50 }}
        open={isDetailsModalVisible}
        onCancel={() => setDetailsModalVisible(false)}
        title={selectedApp?.title}
        footer={
          canAddMoreConnections ? (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={async () => {
                if (selectedApp?.id === "microsoft") {
                  const { data, error } = await supabaseClient.functions.invoke(
                    "integration-accounts/setup",
                    {
                      body: {
                        account: currentTeam?.account_id,
                        account_type: selectedApp.id,
                      },
                    }
                  );
                  if (error) {
                    console.log("Error:", error);
                  } else {
                    window.location.replace(data?.redirect);
                  }
                } else if (selectedApp?.id === "lexware_partner") {
                  const { data, error } = await supabaseClient.functions.invoke(
                    "integration-accounts/setup",
                    {
                      body: {
                        account: currentTeam?.account_id,
                        account_type: selectedApp.id,
                        connection_name: currentTeam?.name,
                      },
                    }
                  );
                  if (error) {
                    console.log("Error:", error);
                  } else {
                    window.location.replace(data?.redirect);
                  }
                } else {
                  setAddConnectionModalVisible(true);
                }
              }}
            >
              {translate("integrations.add_connection")}
            </Button>
          ) : null // Keine Buttons anzeigen, wenn das Limit erreicht ist
        }
        centered
      >
        <List
          itemLayout="horizontal"
          dataSource={connectedAccounts as Account[]}
          renderItem={(account) => (
            <List.Item
              actions={[
                ...(account.account_type !== "lexware_partner"
                  ? [
                      <DeleteButton
                        resource="integration_accounts"
                        recordItemId={account.id}
                        hideText
                        onSuccess={() => refetch()}
                      />,
                    ]
                  : [
                      <Popconfirm
                        title="Are you sure you want to delete this?"
                        onConfirm={async () => {
                          await supabaseClient.functions.invoke(
                            "integration-accounts/revoke",
                            {
                              body: {
                                account_type: "lexware_partner",
                                account: currentTeam?.account_id,
                              },
                            }
                          );
                          await refetch();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button icon={<DeleteOutlined />} danger></Button>
                      </Popconfirm>,
                    ]),
              ]}
            >
              <List.Item.Meta
                title={
                  <>
                    {(account.account_type === "microsoft" ||
                      account.account_type === "smtp") &&
                      (account?.email_sender_addresses
                        ?.map((emailObj) => emailObj.email)
                        .join(", ") ||
                        "No email")}
                    {account.account_type === "sepa" && (
                      <Flex gap={24}>
                        <Flex gap={4} vertical={true}>
                          <Typography.Text strong={true}>IBAN</Typography.Text>
                          {account.credentials.iban}
                        </Flex>
                        <Flex gap={4} vertical={true}>
                          <Typography.Text strong={true}>BIC</Typography.Text>
                          {account.credentials.bic}
                        </Flex>
                      </Flex>
                    )}
                    {account.account_type === "lexware_public" && (
                      <>{account.credentials.api_key}</>
                    )}
                  </>
                }
                description={
                  <Typography.Text style={{ fontSize: 13 }} type="secondary">
                    {dayjs(account.created_at).format(
                      translate("page.settings.date_format")
                    )}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            {translate("settings.integrations.title")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.integrations.intro")}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Input
        placeholder={translate("settings.integrations.search")}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: "100%" }}
      />

      <Form {...formProps} layout="vertical">
        <Tabs
          defaultActiveKey="all"
          onChange={(key) => setActiveTab(key)}
          items={[
            { key: "all", label: translate("integrations.all") },
            { key: "email", label: translate("integrations.email_messaging") },
            { key: "payments", label: translate("integrations.payments") },
            { key: "calendars", label: translate("integrations.calendars") },
            { key: "connected", label: translate("integrations.connected") },
          ]}
        />
        <Row gutter={[32, 32]} style={{ marginTop: 32 }}>
          {getFilteredApps().map((app) => (
            <Col xs={24} sm={12} md={8} key={app.id}>
              <Card
                hoverable
                onClick={() => handleCardClick(app)}
                style={{ height: "100%" }}
              >
                <Space style={{ width: "100%" }} direction="vertical">
                  <Flex justify={"space-between"}>
                    {app?.icon}
                    <div>
                      {groupedProducts[app.id] && (
                        <Tag color="green">connected</Tag>
                      )}
                      {app.comingSoon && (
                        <Tag color="yellow">
                          {translate("buttons.coming_soon")}
                        </Tag>
                      )}
                    </div>
                  </Flex>
                  <Typography.Title level={5}>{app.title}</Typography.Title>
                  <Typography.Text>{app.description}</Typography.Text>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Form>

      {renderDetailsModal()}
      {selectedApp?.id === "smtp" ? (
        <AddSmtpConnectionModal
          isAddConnectionModalVisible={isAddConnectionModalVisible}
          setAddConnectionModalVisible={() =>
            setAddConnectionModalVisible(false)
          }
          form={form}
          refetch={refetch}
          setModalLoading={() => setModalLoading(!modalLoading)}
          emailValidationStatus={emailValidationStatus}
          setEmailValidationStatus={() =>
            setEmailValidationStatus(!emailValidationStatus)
          }
          modalLoading={modalLoading}
        />
      ) : (
        <AddConnectionModal
          visible={isAddConnectionModalVisible}
          onClose={() => setAddConnectionModalVisible(false)}
          form={form}
          appType={selectedApp?.id}
          refetch={refetch}
        />
      )}
    </>
  );
}
