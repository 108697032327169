import { useCallback } from "react";
import { usePipelineContext } from "../contexts/PipelineProvider";
import { supabaseClient } from "../utility";

export default function useModules() {
  const { setCanAccess } = usePipelineContext();
  const getSlugFromUrl = useCallback(() => {
    const pathArray = window.location.pathname.split("/");
    // Assuming the slug is at index 2 in the URL
    return pathArray[1] == "workspaces" ? pathArray[2] : pathArray[1];
  }, []);

  const fetchModuleData = useCallback(
    async (teamSlug) => {
      try {
        const { data, error } = await supabaseClient.rpc("get_accounts");
        const selectedTeam = data?.filter((a: any) => a.slug === teamSlug)[0];
        if (selectedTeam) {
          const modules = await supabaseClient
            .schema("basejump")
            .from("modules")
            .select("*")
            .eq("account", selectedTeam.account_id);
          setCanAccess({
            modules: modules?.data?.at(0),
            personal_modules: selectedTeam?.metadata?.module,
          });
        }
        if (error) {
          throw error;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [setCanAccess]
  );
  return { fetchModuleData, getSlugFromUrl };
}
