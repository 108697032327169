import { useList, useTranslate } from "@refinedev/core";
import { Tree } from "antd";
import Typography from "antd/es/typography/Typography";
import { useEffect, useState } from "react";

type Props = {
  currentTeam: any;
  initialSelectedItems?: any;
  setItems?: (items: any) => void;
};

const VariantTree = ({
  currentTeam,
  initialSelectedItems,
  setItems,
}: Props) => {
  const [treeData, setTreeData] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const translate = useTranslate();
  const { data: productsPropertiesData } = useList({
    resource: "products_properties",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
    meta: { select: "*, products_property_values(*)" },
  });

  useEffect(() => {
    if (productsPropertiesData) {
      const treeDataFormatted = productsPropertiesData.data.map((property) => ({
        key: `property-${property.id}`,
        title: property.name,
        data: property,
        children: (property.products_property_values || []).map((value) => ({
          key: `value-${value.id}`,
          title: value.value,
          data: value,
        })),
      }));

      setTreeData(treeDataFormatted);

      const allKeys = treeDataFormatted.reduce((acc: any, propertyNode) => {
        acc.push(propertyNode.key);
        (propertyNode.children || []).forEach((childNode) =>
          acc.push(childNode.key)
        );
        return acc;
      }, []);
      setExpandedKeys(allKeys);

      // Only set child nodes (products_property_values) as checked
      if (initialSelectedItems) {
        const initialCheckedKeys = initialSelectedItems.flatMap((item) =>
          item.products_property_values.map((value) => `value-${value.id}`)
        );

        setCheckedKeys(initialCheckedKeys);
        setSelectedItems(initialSelectedItems);
      }
    }
  }, [productsPropertiesData, initialSelectedItems]);

  const onCheck = (checkedKeysValue, { checkedNodes }) => {
    setCheckedKeys(checkedKeysValue);

    const selectedData = treeData
      .map((propertyNode) => {
        const selectedValues = (propertyNode.children || [])
          .filter((childNode) => checkedKeysValue.includes(childNode.key))
          .map((childNode) => childNode.data);

        return selectedValues.length > 0
          ? { ...propertyNode.data, products_property_values: selectedValues }
          : null;
      })
      .filter(Boolean);

    setSelectedItems(selectedData);

    if (setItems) {
      setItems(selectedData);
    }
  };
  return treeData?.length > 0 ? (
    <div>
      <Tree
        treeData={treeData}
        onCheck={onCheck}
        checkable
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
        className="variantsTree"
      />
    </div>
  ) : (
    <Typography style={{ fontStyle: "italic" }}>
      {translate("product_items.fields.no_configured_variants")}
    </Typography>
  );
};

export default VariantTree;
