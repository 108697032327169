import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import {
  CanAccess,
  useCreateMany,
  useDelete,
  useList,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {DeleteOutlined, DownOutlined, PlusCircleFilled, PlusOutlined, WarningOutlined} from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  Button,
  Drawer,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Upload,
  UploadFile,
  theme,
  Modal,
  Row,
  Col, Dropdown, MenuProps, Badge, Switch, Typography, Divider,
} from "antd";
import { UploadProps } from "antd/lib";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Text } from "../../../components";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import VariantTree from "../../../components/shop/VariantTree";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import { useDeleteForJunctionTableMany as deleteMany } from "../../../hooks/useDeleteForJunctionTable";
import useFile from "../../../hooks/useFile";
import { IMedia } from "../../../interfaces/general.interface";
import { Category } from "../../../interfaces/shop.interface";
import { supabaseClient } from "../../../utility";
import { updateCategoryPathnames } from "../../../utility/shop";
import SubscriptionProductsList from "../subscription-products/list";
import ProductIssuesList from "./product-issues/list";
import PriceFields from "../../../components/shop/PriceFields";

const ProductItemShow: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<
    | "title"
    | "description"
    | "categories"
    | "images"
    | "netto_price"
    | "sales_tax"
    | "billing_interval"
    | "configured_variants"
    | "type"
  >();

  const [fileList, setFileList] = React.useState<UploadFile[] | undefined>([]);
  const [items, setItems] = React.useState<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imagesLoading, setImagesLoading] = React.useState(true);
  const [typeChange, setTypeChange] = React.useState(false);
  const { currentTeam } = useUser();

  const primaryColor = theme?.useToken()?.token?.colorPrimary;
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();
  const { mutateAsync: updateAsync } = useUpdate();
  const translate = useTranslate();
  const params = useParams();

  const { formProps, form, saveButtonProps, query } = useForm({
    resource: "product_items",
    action: "edit",
    id: params?.id,
    redirect: false,
    meta: { select: "*, product_categories(*)" },
  });
  const { canAccess } = usePipelineContext();

  const { getFileDataURL, removeFile } = useFile();

  const { data, isError, isLoading } = query ?? {};
  const itemsCategory = useMemo(
    () => data?.data.product_categories.map((item) => item.id),
    [data?.data]
  );
  const { data: categoryList } = useList<Category>({
    resource: "product_categories",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const categoriesWithPath = useMemo(
    () =>
      updateCategoryPathnames(categoryList?.data ?? []).filter((item) =>
        itemsCategory?.includes(item.id)
      ),
    [categoryList?.data, itemsCategory]
  );
  const { mutateAsync: createMany } = useCreateMany();

  const closeModal = () => {
    list("product_items");
  };

  const getAllImages = React.useCallback(async () => {
    try {
      setImagesLoading(true); // Set the loading state here before starting

      if (!data?.data.images || data?.data.images.length === 0) {
        setFileList([]);
        return;
      }

      // Fetch all images
      const responses = await Promise.allSettled(
        data?.data.images.map(async (image: IMedia) => {
          try {
            const fetchedImg = await getFileDataURL(image, true);
            if (!fetchedImg) {
              throw new Error("Error reading file");
            }
            if (fetchedImg && typeof fetchedImg === "object") {
              return {
                uid: image?.id ?? "",
                lastModified: fetchedImg.file.lastModified,
                lastModifiedDate: fetchedImg.file.lastModified,
                name: fetchedImg.file.name,
                size: fetchedImg.file.size,
                type: fetchedImg.blob.type,
                percent: 100,
                originFileObj: {
                  uid: image?.id ?? "",
                },
                status: "done",
                thumbUrl: fetchedImg.base64,
              };
            }
          } catch (error: any) {
            console.error(
              `Error fetching image with id ${image?.id}: ${error.message}`
            );
            return null;
          }
        })
      );

      const successfulResponses = responses
        .filter(
          (result): result is PromiseFulfilledResult<any> =>
            result.status === "fulfilled" && result.value !== null
        )
        .map((result) => result.value);

      setFileList(successfulResponses);
    } catch (error: any) {
      console.error("Error in getAllImages:", error.message);
    } finally {
      setImagesLoading(false); // Update loading state in finally to ensure it's run after completion
    }
  }, [data?.data.images, getFileDataURL]);

  useEffect(() => {
    if (data?.data.images) {
      getAllImages();
    } else {
      setFileList([]);
      setImagesLoading(false);
    }
  }, [data?.data.images, getAllImages]);

  useEffect(() => {
    // Setze den anfänglichen gross_price, wenn Daten geladen sind
    if (!isLoading && data?.data && data?.data?.netto_price !== undefined && data?.data?.sales_tax !== undefined) {
      const gross_price = data.data.netto_price * (1 + (data.data.sales_tax || 0) / 100);
      form.setFieldsValue({ gross_price });
    }
  }, [isLoading, data, form]);

  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState(false);
  const [isIssueBasedEnabled, setIsIssueBasedEnabled] = useState(false);

  const [isChangeSubscriptionModalOpen, setIsChangeSubscriptionModalOpen] = useState(false);

  if (isError) {
    closeModal();
    return null;
  }
  if (isLoading) {
    return null;
  }
  const {
    id,
    title = "",
    description,
    images,
    created_at,
    type = "",
    configured_variants,
    netto_price,
    sales_tax,
    price = netto_price * (1 + (sales_tax || 0) / 100),
  } = (data?.data as {
    id?: string;
    title?: string;
    description?: string;
    images?: any[];
    created_at?: string;
    type?: string;
    configured_variants?: any[];
    netto_price?: any;
    sales_tax?: any;
    price?: any;
  }) ?? {};

  // // Berechnung des anfänglichen Bruttopreises
  // const initialGrossPrice = netto_price && sales_tax
  //   ? netto_price * (1 + (sales_tax || 0) / 100)
  //   : 0;

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    await removeFile([`public/shop/product_items/${file.name}`]).then(
      async () => {
        if (params.id) {
          fileList?.filter((file) => {
            return !images?.find((item) => item.id == file.uid);
          });
          const restImages = images?.filter((item) => item.id != file.uid);
          updateAsync({
            resource: "product_items",
            id: params?.id,
            values: { images: restImages ?? [] },
          });
        }
      }
    );
    const index = fileList?.indexOf(file);
    const newFileList = fileList?.slice();
    newFileList?.splice(index ?? 0, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = file.thumbUrl;
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleFileSave = async () => {
    const newFiles = fileList?.filter((file) => {
      return !images?.find((item) => item.id == file.uid);
    });
    const fileArr = newFiles
      ? await Promise.all(
          newFiles.map(async (item: any) => {
            const fileName = `${Date.now()}-${item.name}`;
            const { data: fileData, error } = await supabaseClient.storage
              .from("files")
              .upload(
                `teams/${currentTeam?.account_id}/shop/product_items/${params?.id}/${fileName}`,
                item.originFileObj,
                {
                  //test
                }
              );
            if (error) {
              throw error;
            } else {
              return {
                ...fileData,
                name: fileName,
              };
            }
          })
        )
      : null;

    if (fileArr && fileArr[0] != undefined) {
      return [...(images ?? []), ...(fileArr ?? [])];
    }
  };
  const handleCategoriesSave = async (values: any) => {
    const valuesToDelete = categoriesWithPath
      ?.filter((item) => !values.categories.includes(item.id))
      .map((category) => ({
        product_category: category.id,
        product_item: Number(params?.id),
      }));

    const valuesToAdd = values.categories
      ?.filter(
        (item) => !categoriesWithPath.map((cat) => cat.id).includes(item)
      )
      .map((category) => {
        return {
          product_category: category,
          product_item: params?.id,
          account: currentTeam?.account_id,
        };
      });

    if (valuesToDelete?.length) {
      await deleteMany("product_item_has_product_category", valuesToDelete);
    }

    if (valuesToAdd?.length) {
      await createMany({
        resource: "product_item_has_product_category",
        values: valuesToAdd,
        successNotification: false,
      });
    }
  };
  const handleSubmit = async (values: any) => {
    handleCategoriesSave(values);
    const images = await handleFileSave();
    return (
      formProps.onFinish &&
      formProps.onFinish({
        title: values?.title,
        description: values?.description,
        netto_price: values?.netto_price,
        sales_tax: values?.sales_tax,
        images: images ?? [],
        configured_variants: items?.length > 0 ? items : null,
        account: currentTeam?.account_id,
      })
    );
  };
  const props: UploadProps = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
    listType: "picture-card",
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 6,
    onPreview: handlePreview,
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  const showChangeSubscriptionModal = () => {
    setIsChangeSubscriptionModalOpen(true);
  };

  const handleChangeSubscriptionModalCancel = () => {
    setIsChangeSubscriptionModalOpen(false);
  };

  const handleChangeSubscriptionModalOk = async () => {
    if (isSubscriptionEnabled && !isIssueBasedEnabled) {
      await updateAsync({
        resource: "product_items",
        id: params?.id,
        values: { type: "subscription" },
      });
    }

    if (isIssueBasedEnabled) {
      await updateAsync({
        resource: "product_items",
        id: params?.id,
        values: { type: "issue" },
      });
    }

    setIsChangeSubscriptionModalOpen(false);
  };

  const dropdownItems: MenuProps['items'] = [
    ...(type === "item" ? [
      {
        key: '0',
        label: translate("product_items.buttons.change_to_subscription"),
        //onClick: () => handleChangeToSubscription(),
        onClick: () => showChangeSubscriptionModal(),
      },

    ] : []),
    {
      key: '2',
      label: 'Portotabelle',
      disabled: true,
    },
    {
      key: '3',
      label: 'Warenbestand',
      disabled: true,
    },
    // {
    //   key: '4',
    //   danger: true,
    //   label: 'a danger item',
    // },
  ];


  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>

        <Modal
          title={translate("product_items.subscriptions.title")}
          open={isChangeSubscriptionModalOpen} onOk={handleChangeSubscriptionModalOk}
          onCancel={handleChangeSubscriptionModalCancel}
        >
          <Space direction={"vertical"} size={"large"}>
            <Text>{translate("product_items.subscriptions.description")}</Text>

            <Space>
              <Switch
                checked={isSubscriptionEnabled}
                onChange={(checked) => setIsSubscriptionEnabled(checked)}
              />
              <Text>
                {translate("product_items.subscriptions.title")}
              </Text>
            </Space>

            <Text>{translate("product_items.subscriptions.issue.description")}</Text>

            <Space>
              <Switch
                checked={isIssueBasedEnabled}
                onChange={(checked) => setIsIssueBasedEnabled(checked)}
              />
              <Text>
                {translate("product_items.subscriptions.issue.title")}
              </Text>
            </Space>

          </Space>

        </Modal>

        <Drawer
          open
          onClose={() => closeModal()}
          className="entityDrawer"
          title={
            <Space>
              {title}
            </Space>
          }
          extra={
            <Dropdown menu={{ items: dropdownItems }}>
              <Button
                size={"small"}
                type={"link"}
                onClick={(e) => e.preventDefault()}>
                <Space>
                  {translate("product_items.options")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          }
          footer={
            <Flex justify="space-between" align="center">
              <Text className="ant-text tertiary">
                {translate("product_items.fields.created_at")}{" "}
                {dayjs(created_at).format(
                  translate("page.settings.date_format")
                )}{" "}
                {translate("page.settings.clock")}
              </Text>
              <Space>
                <Popconfirm
                  title={translate("product_items.buttons.delete.title")}
                  description={translate("product_items.buttons.delete_single")}
                  onConfirm={() => {
                    if (id) {
                      deleteMutation(
                        {
                          id,
                          resource: "product_items",
                        },
                        {
                          onSuccess: () => closeModal(),
                        }
                      );
                    }
                  }}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="link" danger icon={<DeleteOutlined />}>
                    {translate("product_items.buttons.delete.title")}
                  </Button>
                </Popconfirm>
                <Button {...saveButtonProps} type="primary">
                  {translate("buttons.save")}
                </Button>
              </Space>
            </Flex>
          }
        >
          <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
            <Form
              {...formProps}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                ...formProps.initialValues,
                type: type === "subscription" ? false : true,
              }}
            >

              <Row gutter={[64,12]}>
                <Col span={14}>
                  <Form.Item
                    name={"title"}
                    label={translate("product_items.fields.title")}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={"description"}
                    label={translate("product_items.fields.description")}
                  >
                    <Input.TextArea
                      autoSize={true}
                      style={{ maxHeight: "20rem" }}
                    />
                  </Form.Item>

                  <PriceFields form={form} />

                </Col>
                <Col span={10}>

                  <Form.Item
                    // name={"images"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    label={translate("product_items.fields.upload_image")}
                  >
                    {imagesLoading ? (
                      <Spin />
                    ) : (
                      <Upload {...props} fileList={fileList}>
                        {fileList && fileList.length < 6 && (
                          <PlusCircleFilled
                            style={{
                              fontSize: "25px",
                              color: primaryColor,
                            }}
                          />
                        )}
                      </Upload>
                    )}
                  </Form.Item>


                </Col>

                <Col span={24}>
                  {canAccess?.personal_modules?.shop?.ecommerce && (
                    <CategoryAutoselect
                      multiple
                      itemProps={{
                        initialValue: categoriesWithPath.map((category) =>
                          Number(category.id)
                        ),
                        label: translate(
                          "product_categories.belongs_to_categories"
                        ),
                      }}
                      name={"categories"}
                    />
                  )}
                </Col>

                <Col span={24}>
                  {canAccess?.personal_modules?.shop?.variants && (
                    <Form.Item
                      name={"configured_variants"}
                      label={translate("product_items.fields.configured_variants")}
                    >
                      <VariantTree
                        currentTeam={currentTeam}
                        setItems={(val) => setItems(val)}
                        initialSelectedItems={configured_variants}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={24}>
                  {typeChange ? (
                    <Spin />
                  ) : (
                    <>
                      {type == "subscription" && (
                        <>
                          <Typography.Text strong>
                            {translate("subscription_products.list.title")}
                          </Typography.Text>
                          <SubscriptionProductsList />
                        </>
                      )}
                      {type == "issue" && (
                        <Tabs>
                          <>
                            <Tabs.TabPane
                              tab={translate("subscription_products.list.title")}
                              key="time"
                            >
                              <SubscriptionProductsList />
                            </Tabs.TabPane>

                            {type == "issue" && (
                              <Tabs.TabPane
                                tab={translate("product_items.product_issues.title")}
                                key="issue"
                              >
                                <ProductIssuesList />
                              </Tabs.TabPane>
                            )}
                          </>
                        </Tabs>
                      )}
                    </>
                  )}
                </Col>

              </Row>






            </Form>

            {/*{("time|issue".includes(type)) && (*/}
            {/*  <SubscriptionProductsList />*/}
            {/*)}*/}

            {/*{type == "issue" && <ProductIssuesList />}*/}
          </Space>
          {children}
        </Drawer>
        <div>
          {previewImage && (
            <Image
              wrapperStyle={{ display: "none" }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </div>
      </>
    </CanAccess>
  );
};
export default ProductItemShow;
