import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ConfigProvider } from "antd";
import deDE from "antd/locale/de_DE";
import enUS from "antd/locale/en_US";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

import {
  AuthPage,
  ErrorComponent,
  ThemedLayoutV2,
  ThemedTitleV2,
  useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import {
  ApartmentOutlined,
  DesktopOutlined,
  LineChartOutlined,
  MailOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { App as AntdApp } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { NavigateToResource } from "./components/CustomNavigateToResource";
import Layout from "./components/Layout/Layout";
import { Sider } from "./components/Layout/sider";
import { AppIcon } from "./components/app-icon";
import { RegisterPage } from "./components/auth/RegisterPage";
import VerifyPage from "./components/auth/VerifyPage";
import NotLogedInInvitationWrapper from "./components/auth/invitation/InviteWrapper";
import { HeaderHeadless } from "./components/header/HeaderHeadless";
import ShowSentEmail from "./components/mailings/ShowSentEmail";
import InvoicesList from "./components/settings/InvoicesList";
import MailingSettings from "./components/settings/MailingSettings";
import ShopSettings from "./components/settings/ShopSettings";
import WorkspacesList from "./components/teams/WorkspacesList";
import SubscriptionContracts from "./components/teams/settings/subscription-contracts";
import TeamSettings from "./components/teams/settings/team-settings";
import { UserProvider } from "./contexts/ContextProvider/userProvider";
import { usePipelineContext } from "./contexts/PipelineProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import useSentry from "./hooks/useSentry";
import AutomationsCreate from "./pages/automations/create";
import AutomationsEdit from "./pages/automations/edit";
import AutomationsList from "./pages/automations/list";
import ExceptionShow from "./pages/exceptions/show";
import IntegrationsList from "./pages/settings/integrations/list";
import InvoiceCreate from "./pages/shop/invoices/create";
import OrdersCreate from "./pages/shop/orders/create";
import OrdersShow from "./pages/shop/orders/show";
import CreateProductItem from "./pages/shop/product-items/create";
import ProductIssuesCreate from "./pages/shop/product-items/product-issues/create";
import ProductIssueShow from "./pages/shop/product-items/product-issues/show";
import ProductItemShow from "./pages/shop/product-items/show";
import SubscriptionProductsCreate from "./pages/shop/subscription-products/create";
import SubscriptionProductsShow from "./pages/shop/subscription-products/show";
import CreateTeam from "./pages/team/create";
import RedirectTeam from "./pages/team/redirectTeam";
import AccountSetup from "./pages/welcome/AccountSetup";
import Invitations from "./pages/welcome/Invitations";
import Onboarding from "./pages/welcome/onboarding";
import authProvider from "./providers/authProvider";
import { themeProvider } from "./providers/themeProvider";
import "./styles/antd.css";
import "./styles/fc.css";
import "./styles/index.css";
import { supabaseClient } from "./utility";
import { RedirectHomeRoute } from "./utility/RedirectHomeRoute";
import { ValidTeamChecker } from "./utility/ValidTeamChecker";
import { capitalizeEveryWord } from "./utility/transform";

const EmptyPipelines = React.lazy(() => import("./pages/pipelines/empty"));

const SettingsList = React.lazy(() => import("./pages/settings"));
const SalesKPIList = React.lazy(
  () => import("./pages/backoffice/SalesKPI/list")
);

const WebshopList = React.lazy(
  () => import("./pages/shop/product-webshop/list")
);

const OrdersList = React.lazy(() => import("./pages/shop/orders/list"));
const WebshopCreate = React.lazy(
  () => import("./pages/shop/product-webshop/create")
);
const WebshopShow = React.lazy(
  () => import("./pages/shop/product-webshop/show")
);
const ProductCategorieList = React.lazy(
  () => import("./pages/shop/product-categories/list")
);
const CreateProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/create")
);
const EditProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/edit")
);
const ProductItemList = React.lazy(
  () => import("./pages/shop/product-items/list")
);
const FileList = React.lazy(() => import("../src/pages/settings/files/list"));
const PipelineItemEdit = React.lazy(
  () => import("../src/pages/pipelines/items/edit")
);
const PipelineItemCreate = React.lazy(
  () => import("../src/pages/pipelines/items/create")
);
const PipelineItemShow = React.lazy(
  () => import("../src/pages/pipelines/items/show/index")
);
const PipelineShow = React.lazy(() => import("../src/pages/pipelines/show"));
const StagesShow = React.lazy(
  () => import("../src/pages/pipelines/stages/show")
);
const PipelineEdit = React.lazy(() => import("../src/pages/pipelines/edit"));
const PipelineAutomationEdit = React.lazy(
  () => import("../src/pages/pipelines/automation/edit")
);
const PipelineStoreShow = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/show")
);
const PipelineStoreList = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/list")
);

const PipelineList = React.lazy(() => import("../src/pages/pipelines/list"));

// const AccountShow = React.lazy(() => import("../src/pages/account/show"));
const TagList = React.lazy(() => import("../src/pages/settings/tags/list"));
const TagShow = React.lazy(() => import("../src/pages/settings/tags/show"));
const TagEdit = React.lazy(() => import("../src/pages/settings/tags/edit"));
const AIShow = React.lazy(() => import("../src/pages/settings/ai"));

const InvoicesListPage = React.lazy(() => import("./pages/shop/invoices/list"));
const MailingsCreate = React.lazy(() => import("./pages/mailings/create"));
const MailingsList = React.lazy(() => import("./pages/mailings/list"));
const MailingsEdit = React.lazy(() => import("./pages/mailings/edit"));
const ContactShow = React.lazy(() => import("../src/pages/contacts/show"));
const ContactCreate = React.lazy(() => import("../src/pages/contacts/create"));
const DashboardPage = React.lazy(() => import("../src/pages/dashboard/index"));
const ContactList = React.lazy(() => import("../src/pages/contacts/list"));
const ExceptionList = React.lazy(() => import("../src/pages/exceptions/list"));

function App() {
  const { pipelines, canAccess } = usePipelineContext();
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: any) => t(key, params as string),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  useSentry();

  return (
    <ConfigProvider
      locale={t("page.settings.locale") === "de" ? deDE : enUS}
      theme={themeProvider}
    >
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <AntdApp>
              <DevtoolsProvider>
                <Refine
                  dataProvider={{
                    default: dataProvider(supabaseClient),
                  }}
                  liveProvider={liveProvider(supabaseClient)}
                  authProvider={authProvider}
                  accessControlProvider={{
                    can: async ({ resource, action }) => {
                      if (
                        resource == "welcome" &&
                        action == "list" &&
                        canAccess?.personal_modules?.welcome == false
                      ) {
                        return { can: false, reason: "Deactivated" };
                      }
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        !canAccess?.modules?.pipeline
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        canAccess?.modules?.pipeline === true &&
                        canAccess?.personal_modules?.pipeline === false
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product_items" ||
                          resource === "subscription_products" ||
                          resource === "orders") &&
                        action === "list" &&
                        (canAccess?.modules?.shop === false ||
                          canAccess?.modules?.shop === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product_items" ||
                          resource === "subscription_products" ||
                          resource === "orders") &&
                        action === "list" &&
                        canAccess?.modules?.shop === true &&
                        !canAccess?.personal_modules?.shop
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "mailings" ||
                          resource === "info_mails" ||
                          resource === "newsletter") &&
                        action === "list" &&
                        (canAccess?.modules?.mailing === false ||
                          canAccess?.modules?.mailing === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }

                      if (
                        (resource === "mailings" ||
                          resource === "info_mails" ||
                          resource === "newsletter") &&
                        action === "list" &&
                        canAccess?.modules?.mailing === true &&
                        !canAccess?.personal_modules?.mailing
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        resource === "automations" &&
                        action === "list" &&
                        (canAccess?.modules?.automations === false ||
                          canAccess?.modules?.automations === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        resource === "automations" &&
                        action === "list" &&
                        (canAccess?.modules?.automations === false ||
                          canAccess?.modules?.automations === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      return { can: true };
                    },
                  }}
                  routerProvider={routerBindings}
                  notificationProvider={useNotificationProvider}
                  i18nProvider={i18nProvider}
                  resources={[
                    // {
                    //   name: "welcome",
                    //   list: "/:teamSlug/welcome",
                    //   meta: {
                    //     label: t("global.welcome"),
                    //     icon: <Popper />,
                    //   },
                    // },
                    {
                      name: "dashboard",
                      list: "/:teamSlug",
                      meta: {
                        label: "Dashboard",
                        icon: <DesktopOutlined />,
                      },
                    },
                    {
                      name: "exceptions",
                      list: "/:teamSlug/exceptions",
                      meta: {
                        label: "Exceptions",
                        hide: true,
                        icon: <SettingOutlined />,
                      },
                    },
                    {
                      name: "contacts",
                      list: "/:teamSlug/contacts",
                      create: "/:teamSlug/contacts/create",
                      edit: "/:teamSlug/contacts/edit/:id",
                      show: "/:teamSlug/contacts/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <TeamOutlined />,
                      },
                    },

                    {
                      name: "automations",
                      list: "/:teamSlug/automations",
                      create: "/:teamSlug/automations/create",
                      edit: "/:teamSlug/automations/edit/:id",
                      show: "/:teamSlug/automations/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <RocketOutlined />,
                      },
                    },
                    {
                      name: "pipelines-menu",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/edit",
                      list: "/:teamSlug/pipelines",
                      meta: {
                        icon: <ApartmentOutlined />,
                        label: "Pipelines",
                      },
                    },
                    ...pipelines,
                    {
                      name: "shop",
                      list: "/:teamSlug/shop",
                      meta: {
                        icon: <ShopOutlined />,
                        label: t("commerce.title", "Commerce"),
                        hide: !canAccess?.personal_modules?.shop?.enabled,
                      },
                    },
                    {
                      name: "product_categories",
                      list: "/:teamSlug/shop/product-categories",
                      create: "/:teamSlug/shop/product-categories/create",
                      edit: "/:teamSlug/shop/product-categories/edit/:id",
                      show: "/:teamSlug/shop/product-categories/show/:id",

                      meta: {
                        canDelete: true,
                        hide: true, //!canAccess?.personal_modules?.shop?.ecommerce,
                        parent: "shop",
                        label: t(
                          "commerce.product_categories",
                          "Product categories"
                        ),
                      },
                    },
                    {
                      name: "product_items",
                      list: "/:teamSlug/shop/product-items",
                      create: "/:teamSlug/shop/product-items/create",
                      edit: "/:teamSlug/shop/product-items/edit/:id",
                      show: "/:teamSlug/shop/product-items/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.product_items", "Product items"),
                      },
                    },
                    {
                      name: "orders",
                      list: "/:teamSlug/shop/orders",
                      create: "/:teamSlug/shop/orders/create",
                      edit: "/:teamSlug/shop/orders/edit/:id",
                      show: "/:teamSlug/shop/orders/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.orders", "Orders"),
                      },
                    },
                    {
                      name: "invoices",
                      list: "/:teamSlug/shop/invoices",
                      create: "/:teamSlug/shop/invoices/create",
                      edit: "/:teamSlug/shop/invoices/edit/:id",
                      show: "/:teamSlug/shop/invoices/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.invoices", "Invoices"),
                      },
                    },
                    {
                      name: "webshops",
                      list: "/:teamSlug/shop/webshop",
                      create: "/:teamSlug/shop/webshop/create",
                      edit: "/:teamSlug/shop/webshop/edit/:id",
                      show: "/:teamSlug/shop/webshop/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("page.shop", "Shop"),
                        hide: !canAccess?.personal_modules?.shop?.webshops,
                      },
                    },

                    {
                      name: "product_issues",
                      list: "/:teamSlug/shop/product-items/show/:id/",
                      create:
                        "/:teamSlug/shop/product-items/:id/product-issues/create",
                      show: "/:teamSlug/shop/product-items/show/:id/product-issues/:issueId",
                      meta: {
                        canDelete: true,
                        hide: true,
                        parent: "product_items",
                        label: t("commerce.product_items", "Product items"),
                      },
                    },
                    {
                      name: "subscription_products",
                      list: "/:teamSlug/shop/product-items/show/:prodId/subscription-products",
                      create:
                        "/:teamSlug/shop/product-items/show/:prodId/subscription-products/create",
                      edit: "/:teamSlug/shop/product-items/show/:prodId/subscription-products/edit/:subId",
                      show: "/:teamSlug/shop/product-items/show/:prodId/subscription-products/show/:subId",
                      meta: {
                        canDelete: true,
                        hide: true,
                        parent: "product_items",
                        label: t(
                          "subscription_products.list.title",
                          "Subscription Products"
                        ),
                      },
                    },

                    // { name: "mailings", list: "/:teamSlug/mailings" },

                    {
                      name: "info_mails",
                      list: "/:teamSlug/mailings",
                      create: "/:teamSlug/mailings/create",
                      edit: "/:teamSlug/mailings/edit/:id",
                      show: "/:teamSlug/mailings/show/:id",
                      meta: {
                        icon: <MailOutlined />,
                        canDelete: true,
                        label: "Mailings",
                      },
                    },

                    {
                      name: "pipeline_items",
                      list: "/:teamSlug/pipelines/show/:pipelineId/items",
                      create:
                        "/:teamSlug/pipelines/show/:pipelineId/items/create",
                      show: "/:teamSlug/pipelines/show/:pipelineId/items/:id",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/items/edit:id",
                      meta: {
                        hide: true,
                        parent: "pipelines",
                      },
                    },

                    {
                      name: "settings",
                      list: "/:teamSlug/settings",
                      meta: { icon: <SettingOutlined />, hide: true },
                    },
                    {
                      name: "account",
                      list: "/account",
                      meta: {
                        label: t("settings.account"),
                        hide: true,
                      },
                    },
                    {
                      name: "team",
                      list: "workspaces/:teamSlug/settings/team",
                      meta: {
                        parent: "settings",
                        label: t("settings.team"),
                        hide: true,
                      },
                    },

                    {
                      name: "pipelines",
                      list: "workspaces/:teamSlug/settings/pipelines",
                      create: "workspaces/:teamSlug/settings/pipelines/create",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/settings",
                      meta: {
                        parent: "settings",
                        canDelete: true,
                        hide: true,
                      },
                    },
                    {
                      name: "pipeline_stages_custom_field",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/settings/custom-fields",
                      meta: {
                        parent: "settings",
                        hide: true,
                      },
                    },
                    {
                      name: "pipelines-store",
                      list: "workspace/:teamSlug/settings/pipelines/store",
                      show: "workspace/:teamSlug/settings/pipelines/store/show/:id",
                      meta: {
                        parent: "pipelines",
                        hide: true,
                      },
                    },
                    {
                      name: "pipeline_stages",
                      show: "/:teamSlug/pipelines/show/:pipelineId/:stageId",
                    },
                    {
                      name: "groups",
                      list: "workspace/:teamSlug/settings/groups",
                      create: "workspace/:teamSlug/settings/groups/create",
                      edit: "workspace/:teamSlug/settings/groups/edit/:id",
                      show: "workspace/:teamSlug/settings/groups/show/:id",
                      meta: {
                        parent: "settings",
                        canDelete: true,
                        hide: true,
                      },
                    },
                    {
                      name: "files",
                      list: "workspace/:teamSlug/settings/files",
                      meta: { parent: "settings", hide: true },
                    },
                    {
                      name: "team-members",
                      list: "/:teamSlug/team-members",
                      meta: { hide: true },
                    },
                    {
                      name: "sales-kpi",
                      list: "/:teamSlug/sales-kpi",
                      meta: {
                        icon: <LineChartOutlined />,
                        hide: true,
                      },
                    },
                    {
                      name: "integrations",
                      list: "workspace/:teamSlug/settings/integrations",
                      create:
                        "workspaces/:teamSlug/settings/integrations/create",
                      edit: "workspaces/:teamSlug/settings/show/:id",
                      meta: {
                        parent: "settings",
                        canDelete: true,
                        hide: true,
                      },
                    },
                  ]}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    useNewQueryKeys: true,
                    projectId: "m5CFOK-FLhH7r-EYpiQH",
                    liveMode: "auto",
                    disableTelemetry: true,
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <RedirectHomeRoute />
                        </Authenticated>
                      }
                    />

                    <Route
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <UserProvider outsideTeam>
                            <ThemedLayoutV2
                              Header={() => <HeaderHeadless />}
                              Sider={() => <></>}
                            >
                              <Outlet />
                            </ThemedLayoutV2>
                          </UserProvider>
                        </Authenticated>
                      }
                    >
                      <Route
                        path="workspaces"
                        element={
                          <WorkspacesList>
                            <Outlet />
                          </WorkspacesList>
                        }
                      >
                        <Route
                          path=":teamSlug"
                          element={
                            <ValidTeamChecker>
                              <Outlet />
                            </ValidTeamChecker>
                          }
                        >
                          <Route
                            element={
                              <Suspense>
                                <SettingsList>
                                  <Outlet />
                                </SettingsList>
                              </Suspense>
                            }
                            path="settings"
                          >
                            <Route
                              path="team"
                              element={
                                <Suspense>
                                  <TeamSettings>
                                    <Outlet />
                                  </TeamSettings>
                                </Suspense>
                              }
                            />
                            <Route
                              path="subscription-contracts"
                              element={
                                <Suspense>
                                  <SubscriptionContracts>
                                    <Outlet />
                                  </SubscriptionContracts>
                                </Suspense>
                              }
                            />
                            <Route
                              path="invoices"
                              element={
                                <Suspense>
                                  <InvoicesList>
                                    <Outlet />
                                  </InvoicesList>
                                </Suspense>
                              }
                            />
                            <Route
                              path="pipelines"
                              element={
                                <Suspense>
                                  {/* <CanAccess
                                    resource="pipelines"
                                    action="settings"
                                    fallback={t("module.noAccessTitle")}
                                  > */}
                                  <PipelineList>
                                    <Outlet />
                                  </PipelineList>
                                  {/* </CanAccess> */}
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="integrations"
                              element={
                                <Suspense>
                                  <IntegrationsList />
                                </Suspense>
                              }
                            />
                            <Route
                              path="shop"
                              element={
                                <Suspense>
                                  <ShopSettings />
                                </Suspense>
                              }
                            />
                            <Route
                              path="mailing"
                              element={
                                <Suspense>
                                  <MailingSettings />
                                </Suspense>
                              }
                            />
                            <Route
                              path="ai"
                              element={
                                <Suspense>
                                  <AIShow />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    <Route
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <UserProvider>
                            <ThemedLayoutV2
                              //Header={() => <HeaderV2 />}
                              Header={() => <></>}
                              Sider={() => <Sider />}
                              Title={({ collapsed }) => (
                                <ThemedTitleV2
                                  collapsed={collapsed}
                                  text="CRM"
                                  icon={<AppIcon />}
                                />
                              )}
                            >
                              <Layout>
                                <Outlet />
                              </Layout>
                            </ThemedLayoutV2>
                          </UserProvider>
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/:teamSlug"
                        element={
                          <ValidTeamChecker>
                            <Outlet />
                          </ValidTeamChecker>
                        }
                      >
                        <Route
                          element={
                            <Suspense>
                              <DashboardPage>
                                <Outlet />
                              </DashboardPage>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="contact-show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                          <Route
                            path="deal-show/:itemId"
                            element={
                              <Suspense>
                                <PipelineItemShow />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="welcome"
                          element={
                            <Suspense>
                              <Onboarding />
                              <Outlet />
                            </Suspense>
                          }
                        />
                        <Route
                          path="contacts"
                          element={
                            <Suspense>
                              {/* <CanAccess
                                resource="contacts"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              > */}
                              <ContactList>
                                <Outlet />
                              </ContactList>
                              {/* </CanAccess> */}
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <ContactCreate>
                                  <Outlet />
                                </ContactCreate>
                              </Suspense>
                            }
                          />
                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="exceptions"
                          element={
                            <Suspense>
                              {/* <CanAccess
                                resource="contacts"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              > */}
                              <ExceptionList>
                                <Outlet />
                              </ExceptionList>
                              {/* </CanAccess> */}
                            </Suspense>
                          }
                        >
                          <Route index element={null} />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense fallback={<div>Loading...</div>}>
                                <ExceptionShow>
                                  <Outlet />
                                </ExceptionShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="shop"
                          element={
                            // <CanAccess fallback={t("module.noAccessTitle")}>
                            <Outlet />
                            // </CanAccess>
                          }
                        >
                          <Route
                            path="webshop"
                            element={
                              <Suspense>
                                <WebshopList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />

                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <WebshopCreate />
                                </Suspense>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <WebshopShow />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="product-categories"
                            element={
                              <Suspense>
                                <ProductCategorieList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />

                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <CreateProductCategory />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <EditProductCategory />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="product-items"
                            element={
                              <Suspense>
                                <ProductItemList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <ProductItemShow>
                                    <Outlet />
                                  </ProductItemShow>
                                </Suspense>
                              }
                            >
                              <Route index element={null} />
                              <Route
                                path="product-issues/:issueId"
                                element={<ProductIssueShow />}
                              />
                              <Route
                                path="product-issues/create"
                                element={<ProductIssuesCreate />}
                              />
                              <Route
                                path="subscription-products"
                                element={<Outlet />}
                              >
                                <Route index element={null} />
                                <Route
                                  path="create"
                                  element={
                                    <Suspense>
                                      <SubscriptionProductsCreate>
                                        <Outlet />
                                      </SubscriptionProductsCreate>
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="show/:subId"
                                  element={
                                    <Suspense>
                                      <SubscriptionProductsShow>
                                        <Outlet />
                                      </SubscriptionProductsShow>
                                    </Suspense>
                                  }
                                />
                              </Route>
                            </Route>
                            <Route
                              path="create"
                              element={<CreateProductItem />}
                            />
                          </Route>

                          <Route
                            path="orders"
                            element={
                              <Suspense>
                                <OrdersList>
                                  <Outlet />
                                </OrdersList>
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <OrdersCreate />
                                </Suspense>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <OrdersShow>
                                    <Outlet />
                                  </OrdersShow>
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="invoices"
                            element={
                              <Suspense>
                                <InvoicesListPage>
                                  <Outlet />
                                </InvoicesListPage>
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <InvoiceCreate />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>

                        <Route path="mailings" element={<Outlet />}>
                          <Route
                            index
                            element={
                              <Suspense>
                                <MailingsList />
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ShowSentEmail />
                              </Suspense>
                            }
                          />
                          <Route
                            path="edit/:id"
                            element={
                              <Suspense>
                                <MailingsEdit />
                              </Suspense>
                            }
                          />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <MailingsCreate />
                              </Suspense>
                            }
                          />
                        </Route>

                        <Route
                          index
                          element={<NavigateToResource resource="tags" />}
                        />

                        <Route
                          element={
                            <Suspense>
                              <SettingsList>
                                <Outlet />
                              </SettingsList>
                            </Suspense>
                          }
                          path="settings"
                        >
                          <Route index element={null} />
                          <Route
                            path="subscription-contracts"
                            element={
                              <Suspense>
                                <SubscriptionContracts>
                                  <Outlet />
                                </SubscriptionContracts>
                              </Suspense>
                            }
                          />
                          <Route
                            path="invoices"
                            element={
                              <Suspense>
                                <InvoicesList>
                                  <Outlet />
                                </InvoicesList>
                              </Suspense>
                            }
                          />
                          <Route
                            path="team"
                            element={
                              <Suspense>
                                <TeamSettings>
                                  <Outlet />
                                </TeamSettings>
                              </Suspense>
                            }
                          />
                          {/* <Route
                            path="team/create"
                            element={
                              <Suspense>
                                <TeamCreate />
                              </Suspense>
                            }
                          /> */}
                          <Route
                            path="shop"
                            element={
                              <Suspense>
                                <ShopSettings />
                              </Suspense>
                            }
                          />

                          <Route path="pipelines">
                            <Route
                              element={
                                <Suspense>
                                  <PipelineList>
                                    <Outlet />
                                  </PipelineList>
                                </Suspense>
                              }
                            >
                              <Route index element={null} />
                            </Route>
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <PipelineEdit />
                                </Suspense>
                              }
                            />
                            <Route
                              path="store"
                              element={
                                <Suspense>
                                  <PipelineStoreList>
                                    <Outlet />
                                  </PipelineStoreList>
                                </Suspense>
                              }
                            >
                              <Route
                                path="show/:storePipelineId"
                                element={
                                  <Suspense>
                                    <PipelineStoreShow />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="show/:pipelineId/automation/:pipelineStageId"
                              element={
                                // <Layout action="edit" resource="automation">
                                <Suspense>
                                  <PipelineAutomationEdit />
                                </Suspense>
                                // </Layout>
                              }
                            />

                            <Route
                              path=":id/:stageId"
                              element={
                                // <Layout
                                //   action="show"
                                //   resource="pipeline_stages"
                                // >
                                <Suspense>
                                  <StagesShow />
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                          <Route
                            path="groups"
                            element={
                              // <Layout action="list" resource="groups">
                              <Suspense>
                                <TagList>
                                  <Outlet />
                                </TagList>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout action="show" resource="groups">
                                <Suspense>
                                  <TagShow>
                                    <Outlet />
                                  </TagShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <TagEdit>
                                    <Outlet />
                                  </TagEdit>
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="files">
                            <Route
                              index
                              element={
                                <Suspense>
                                  <FileList />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="integrations"
                            element={
                              <Suspense>
                                <IntegrationsList />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="pipelines"
                          element={
                            <Suspense>
                              <EmptyPipelines />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId/settings"
                          element={
                            // <Layout action="edit" resource="pipelines">
                            <Suspense>
                              <PipelineEdit />
                            </Suspense>
                            // </Layout>
                          }
                        />

                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId"
                          element={
                            <Suspense>
                              <StagesShow />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId/automation"
                          element={
                            <Suspense>
                              <PipelineAutomationEdit />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId"
                          element={
                            <Suspense>
                              {/* <CanAccess
                                resource="pipelines"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              > */}
                              <PipelineShow>
                                <Outlet />
                              </PipelineShow>
                              {/* </CanAccess> */}
                            </Suspense>
                          }
                        >
                          <Route
                            path="items/:itemId"
                            element={
                              // <Layout action="show" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemShow />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/create"
                            element={
                              // <Layout action="create" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemCreate />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/edit/:itemId"
                            element={
                              // <Layout action="edit" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemEdit />
                              </Suspense>
                              // </Layout>
                            }
                          />
                        </Route>

                        <Route
                          path="*"
                          element={
                            <Layout action="show" resource="pipeline_items">
                              <ErrorComponent />
                            </Layout>
                          }
                        />
                        <Route path="sales-kpi">
                          <Route
                            index
                            element={
                              <Suspense>
                                <SalesKPIList />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="automations"
                          element={
                            <Suspense>
                              <AutomationsList>
                                <Outlet />
                              </AutomationsList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <AutomationsCreate>
                                  <Outlet />
                                </AutomationsCreate>
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="automations/edit/:id"
                          element={
                            <Suspense>
                              <AutomationsEdit />
                            </Suspense>
                          }
                        />
                      </Route>
                    </Route>

                    <Route
                      path="team"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <RedirectTeam />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="team/create"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <CreateTeam />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="team/invitations"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<NotLogedInInvitationWrapper />}
                        >
                          <Invitations />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="/account/setup"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <AccountSetup />
                        </Authenticated>
                      }
                    />
                    <Route
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<Outlet />}
                        >
                          <NavigateToResource />
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/login"
                        element={
                          <AuthPage
                            type="login"
                            wrapperProps={{
                              style: {
                                background: "url('/images/authpage-login.jpg')",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title=""
                            formProps={{
                              initialValues: { email: "", password: "" },
                            }}
                          />
                        }
                      />

                      <Route
                        path="/register"
                        element={
                          <RegisterPage
                            wrapperProps={{
                              style: {
                                background:
                                  "url('/images/authpage-register.jpg')",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title={t(
                              "pages.register.welcome",
                              "Sign up now and try Tellie for free and without obligation."
                            )}
                          />
                        }
                      />
                      <Route
                        path="/forgot-password"
                        element={
                          <AuthPage
                            type="forgotPassword"
                            wrapperProps={{
                              style: {
                                background:
                                  "linear-gradient(45deg, #3f96ff 30%, #6fC6ff 90%)",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title=""
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/update-password"
                      element={
                        <AuthPage
                          type="updatePassword"
                          title={
                            <ThemedTitleV2
                              collapsed={false}
                              text="CRM"
                              icon={<AppIcon />}
                            />
                          }
                        />
                      }
                    />
                    <Route path="/verify" element={<VerifyPage />} />
                  </Routes>

                  <RefineKbar />
                  <UnsavedChangesNotifier />
                  <DocumentTitleHandler
                    handler={({ resource, action, params }) => {
                      return (
                        `${params?.id ? `#${params.id}` : ""} ${
                          action !== "list"
                            ? capitalizeEveryWord(action ?? "")
                            : ""
                        } ${capitalizeEveryWord(
                          resource?.name == "info_mails"
                            ? "Mailings"
                            : resource?.name ?? ""
                        )}`.trim() + " | Tellie"
                      );
                    }}
                  />
                </Refine>
              </DevtoolsProvider>
            </AntdApp>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
