import { useModalForm } from "@refinedev/antd";
import {
  CanAccess,
  useCreateMany,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Form, Input, Modal, Typography, UploadFile } from "antd";
import React from "react";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
type Props = {
  disableRedirect?: boolean;
  onSuccess?: (data: any) => void;
  changeView?: () => void;
};
export default function CreateProductItem({
  disableRedirect,
  changeView,
  onSuccess,
}: Props) {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);

  const { canAccess } = usePipelineContext();

  const [productCategories, setProductCategories] = React.useState<number[]>(
    []
  );
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const { show, list } = useNavigation();

  const { mutateAsync } = useUpdate();
  const { mutateAsync: createMany } = useCreateMany({});
  const { modalProps, formProps, close, open } = useModalForm<any>({
    resource: "product_items",
    action: "create",
    redirect: disableRedirect ? false : "show",
    defaultVisible: true,
    // onMutationSuccess: async (data) => {
    //   try {
    //     if (!data?.data?.id) {
    //       console.error("no id provided");
    //     } else {
    //       let fileArr: any[] = [];
    //       if (fileList) {
    //         fileArr = await Promise.all(
    //           fileList.map(async (file: any) => {
    //             const fileName = `${Date.now()}-${file.name}`;
    //             const { data: fileData, error } = await supabaseClient.storage
    //               .from("files")
    //               .upload(
    //                 `teams/${currentTeam?.account_id}/shop/product_items/${data?.data?.id}/${fileName}`,
    //                 file.originFileObj,
    //                 {
    //                   //test
    //                 }
    //               );
    //             if (error) {
    //               throw error;
    //             } else {
    //               return {
    //                 ...fileData,
    //                 name: fileName,
    //               };
    //             }
    //           })
    //         );
    //       }
    //       setFileList([]);
    //       if (productCategories) {
    //         await createMany({
    //           resource: "product_item_has_product_category",
    //           values: productCategories.map((cat) => ({
    //             product_category: cat,
    //             product_item: data.data.id,
    //             account: currentTeam?.account_id,
    //           })),
    //           invalidates: ["all"],
    //           successNotification: false,
    //         });
    //       }
    //       await mutateAsync({
    //         resource: "product_items",
    //         id: data.data.id,
    //         values: { images: fileArr },
    //         successNotification: false,
    //         invalidates: ["all"],
    //       });

    //       !disableRedirect ? show("product_items", data.data.id) : close?.();
    //       if (onSuccess) {
    //         onSuccess(data?.data);
    //       }
    //     }
    //   } catch (error: any) {
    //     //
    //     console.log("error", error.message);
    //   }
    // },
    warnWhenUnsavedChanges: !disableRedirect,
    successNotification: disableRedirect && false,
    invalidates: ["all"],
  });

  const handleFinish = async (values: any) => {
    try {
      formProps?.onFinish?.({
        ...values,
        type: "item",
        account: currentTeam?.account_id,
      });
    } catch (error) {
      //
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <Modal
        {...modalProps}
        open={open}
        centered
        title={translate("product_items.create.add_product_item")}
        width={760}
        onCancel={() => {
          if (!disableRedirect) {
            list("product_items", "replace");
          } else {
            close();
            changeView?.();
          }
        }}
        okText={translate("product_items.buttons.add_product")}
      >
        <Form
          {...formProps}
          onFinish={handleFinish}
          layout="vertical"
          initialValues={{ type: "item" }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} />
          </Form.Item>
          {canAccess?.personal_modules?.shop?.ecommerce && (
            <CategoryAutoselect
              multiple
              name="product_categories"
              onSelect={(items) => setProductCategories(items)}
            />
          )}

          <Typography.Text type={"secondary"}>
            {translate("product_items.create.info")}
          </Typography.Text>
        </Form>
      </Modal>
    </CanAccess>
  );
}
