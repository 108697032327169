import React, { useEffect, useState } from "react";

import { useThemedLayoutContext } from "@refinedev/antd";
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated, useGetIdentity,
  useLink, useList,
  useLogout,
  useMenu,
  useNavigation,
  useTranslate,
} from "@refinedev/core";

import {
  CheckOutlined,
  DownOutlined,
  LayoutOutlined,
  LogoutOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  UnorderedListOutlined,
  UserOutlined, WarningOutlined, LineChartOutlined, UserAddOutlined, CustomerServiceOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Flex,
  Grid,
  Layout,
  Menu,
  MenuProps,
  Space,
  Typography,
  theme,
  Col,
} from "antd";

import useKey from "use-key-hook";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import useFile from "../../hooks/useFile";
import useModules from "../../hooks/useModules";
import { getContrastColor } from "../../utility";
import SearchModal from "./search";
import "./sider.css";
import AccountShow from "../../pages/account/show";

import * as Sentry from "@sentry/react";

const { SubMenu } = Menu;
const { useToken } = theme;

export const Sider: React.FC = () => {
  const [searchDialog, setSearchDialog] = useState<boolean>(false);
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const breakpoint = Grid.useBreakpoint();
  const { token } = useToken();
  const { fetchModuleData } = useModules();
  const { canAccess } = usePipelineContext();
  const { mutate: logout } = useLogout();
  const translate = useTranslate();
  const { list, replace } = useNavigation();
  const {
    siderCollapsed,
    setSiderCollapsed,
    mobileSiderOpen,
    setMobileSiderOpen,
  } = useThemedLayoutContext();

  const Link = useLink();
  const { menuItems, selectedKey } = useMenu();
  const { currentTeam, teamMenuItems, refetchTeams } = useUser();
  const [avatarsrc, setAvatar] = React.useState<string>();
  const { getFileDataURL } = useFile();

  const [feedback, setFeedback] = useState<any>();

  useEffect(() => {
    setFeedback(Sentry.getFeedback() as any);
  }, []);

  const { data: exceptionList } = useList({
    resource: "exception_case",
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
      {
        field: "closed_at",
        value: null,
        operator: "null",
      },
    ],
  });

  const { data: me } = useGetIdentity<any>();

  const { data: invitations } = useList({
    resource: "user_invitations",
    filters: [
      {
        field: "email",
        value: me?.email ?? "",
        operator: "eq",
      },
    ],
    meta: {
      schema: "basejump",
      select:
        "*, team:accounts!basejump_user_invitations_account_id_fkey(*), invited_by:accounts!basejump_user_invitations_invited_by_account_fkey(*)",
    },
  });

  useEffect(() => {
    const getAvatar = async () => {
      const res: any = await new Promise((resolve, reject) => {
        if (!currentTeam?.metadata?.avatar?.image) {
          reject();
        }
        const fetchedImg = getFileDataURL(currentTeam?.metadata?.avatar?.image);

        resolve(fetchedImg);
      });
      setAvatar(res);
      localStorage.setItem("avatarSrc", res);
    };
    if (currentTeam?.metadata?.avatar?.image) {
      getAvatar();
    }
    if (currentTeam?.metadata && !currentTeam?.metadata?.avatar?.image) {
      setAvatar(undefined);
      localStorage.removeItem("avatarSrc");
    }
  }, [currentTeam, getFileDataURL]);
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;


  const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
    return tree.map((item: ITreeMenu) => {
      const {
        icon,
        label,
        route,
        key,
        name,
        children,
        parentName,
        meta,
        options,
      } = item;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { meta: meta2, ...i } = item;

      if (children.length > 0) {
        return (
          <CanAccess
            key={i.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: i,
            }}
          >
            <SubMenu
              key={item.key}
              icon={icon ?? <UnorderedListOutlined />}
              className={"showOnHoverParent"}
              title={
                <>
                  <Flex justify={"space-between"}>
                    <span>{label}</span>
                    {meta?.secondaryButton}
                  </Flex>
                  {meta?.modal ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      {meta?.modal}
                    </div>
                  ) : null}
                </>
              }
            >
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = key === selectedKey;
      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
        undefined && children.length === 0
      );

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={item.key}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route ?? ""}>{label}</Link>
            {!siderCollapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const items = renderTreeView(menuItems, selectedKey);

  const renderMenu = () => {

    return (
      <Menu
        subMenuCloseDelay={0.2}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="vertical"
        style={{
          paddingTop: "8px",
          border: "none",
          overflow: "auto",
          background: "transparent",
          fontWeight: "normal",
        }}
      >
        {items}
      </Menu>
    );
  };

  const itemsTeam = teamMenuItems
    ?.flatMap((item: any) => {
      const currentSelected = item.key == currentTeam?.account_id;
      return [
        {
          ...item,
          disabled: currentSelected,
          icon: (
            <Col>
              {currentSelected && <CheckOutlined />}
            </Col>
          ),
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {
                if (!currentSelected) {
                  setMobileSiderOpen(false);
                  refetchTeams;
                  fetchModuleData(item.slug);
                }
              }}
            >
              {item.label}
            </a>
          ),
        },
      ];
    });

  const renderDrawerSider = () => {
    return (
      <>
        <SearchModal
          openDialog={searchDialog}
          handleCancel={() => setSearchDialog(false)}
        />
        <Drawer
          open={mobileSiderOpen}
          onClose={() => setMobileSiderOpen(false)}
          placement="left"
          closable={false}
          width={256}
          styles={{ body: { padding: 0 } }}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              width={500}
              style={{
                height: "100vh",
                borderRight: `1px solid ${token.colorBorderBg}`,
              }}
            >
              <Flex style={{ padding: "10px 0" }} justify="space-between">
                <Dropdown
                  placement="bottomRight"
                  overlayStyle={{ width: 276, padding: 6 }}
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "2",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              list("settings", "replace");
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <SettingOutlined />{" "}
                              {translate("settings.settings", "Settings")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        key: "4",
                        label: (
                          <a rel="noopener noreferrer">
                            <Space>
                              <StarOutlined style={{ color: "orange" }} />{" "}
                              {translate("settings.upgrade", "Upgrade to Pro")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        type: "divider",
                      },

                      //...(itemsTeam ?? []),
                      {
                        type: "divider",
                      },
                      {
                        key: "15",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              list("account", "push");
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <UserOutlined />{" "}
                              {translate("buttons.my_profile", "My Profile")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        type: "divider",
                      },
                      {
                        key: "16",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              logout();
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <LogoutOutlined /> {translate("buttons.logout")}
                            </Space>
                          </a>
                        ),
                      },
                    ],
                  }}
                >
                  <Button type="link">
                    <Badge offset={[0, -20]}>
                      <Space align="center">
                        <Avatar
                          shape="circle"
                          size="default"
                          src={avatarsrc ?? imageurl}
                          style={{
                            backgroundColor:
                              avatarsrc || imageurl
                                ? "transparent"
                                : currentTeam?.metadata?.avatar?.color,
                            color: getContrastColor(
                              currentTeam?.metadata?.avatar?.color ?? "#000"
                            ),
                          }}
                        >
                          {currentTeam?.name?.charAt(0)}
                        </Avatar>
                        {!siderCollapsed && (
                          <Typography.Text
                            ellipsis={true}
                            style={{ maxWidth: "170px", padding: 0 }}
                          >
                            {currentTeam?.name}
                          </Typography.Text>
                        )}
                        <DownOutlined style={{ fontSize: 10 }} />
                      </Space>
                    </Badge>
                  </Button>
                </Dropdown>
                <Space>
                  <Button
                    type="text"
                    onClick={() => setSiderCollapsed(!siderCollapsed)}
                    style={siderCollapsed ? { left: 10 } : {}}
                    size="large"
                  >
                    <LayoutOutlined />
                  </Button>
                </Space>
              </Flex>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
      </>
    );
  };
  useKey(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_pressedKey: any, _event: any) => {
      setSearchDialog(!searchDialog);
    },
    {
      detectKeys: ["/"],
    }
  );
  const imageurl = React.useMemo(() => localStorage.getItem("avatarSrc"), []);
  if (isMobile) {
    return renderDrawerSider();
  }
  const siderStyles: React.CSSProperties = {
    borderRight: `1px solid #eee`,
    position: "sticky",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 999,
  };


  const bottomMenu: MenuProps["items"] = [
    {
      key: "100",
      icon: <SearchOutlined />,
      onClick: () => {
        setSearchDialog(true);
      },
      label: translate("search.search", "Search"),
    },
    ...(exceptionList?.data.length ? [
      {
        key: "90",
        onClick: () => replace( currentTeam?.slug + "/exceptions"),
        label: (
          <>

            <Badge
              count={exceptionList?.data.length}
              size="small"
              offset={[2, 0]}
            >
              {translate("settings.exceptions")}
            </Badge>

          </>
        ),
        icon: <WarningOutlined />,
      }] : []),
    ...(invitations?.data.length ? [
      {
        key: "91",
        onClick: () => replace("workspaces"),
        label: (
          <>

            <Badge
              count={invitations?.data?.length}
              size="small"
              offset={[2, 0]}
            >
              {translate("settings.invitations", "New invitations")}
            </Badge>

          </>
        ),
        icon: <UserAddOutlined />,
      }
    ] : []),
    {
      key: "101",
      onClick: () => replace('/workspaces/' + currentTeam?.slug + "/settings/team"),
      label: translate("settings.settings", "Settings"),
      icon: <SettingOutlined />,
    },
    {
      key: "102",
      onClick: async () => {
        if (feedback) {
          const form = await feedback.createForm();
          form.appendToDom();
          form.open();
        }
      },
      label: translate("sentry.feedback.triggerLabel"),
      icon: <CustomerServiceOutlined />,
    },
    { type: 'divider' },
    {
      key: 'usermenu',
      icon: <UserOutlined />,
      label: translate("settings.account"),
      //theme: menuTheme,
      children: [
        {
          key: 'u',
          icon: <UserOutlined />,
          onClick: () => setOpenAccountModal(true),
          label: translate("buttons.my_profile", "My Profile")
        },

        // show Switcher only if there are more than one team (workspaces)
        ...(itemsTeam && itemsTeam?.length > 1 ? (
            [
              { type: 'divider' },
              {
                type: 'group', // Must have
                label: translate('teams.workspaces'),
              },
              ...(itemsTeam ?? []),
            ]
          )
          : []),

        { type: 'divider' },
        {
          key: '2',
          icon: <SettingOutlined />,
          onClick: () => replace("workspaces"),
          label: translate("teams.manage_workspaces")
        },

        ...(canAccess?.modules?.sales_kpi ? (
          [
            { type: 'divider' },
            {
              icon: <LineChartOutlined />,
              onClick: () => replace(`/${currentTeam?.slug}/sales-kpi`),
              label: translate("salesKpi.title", "Tellie Statistics"),
            }
          ]
          ) : []),

        { type: 'divider' },

        {
          key: 'l',
          icon: <LogoutOutlined />,
          onClick: () => {
            logout();
            setMobileSiderOpen(false);
          },
          label: translate("buttons.logout")
        },
      ],
    },

  ];


  const selectedKeys = searchDialog ? [] : [];


  return (
    <>
      <SearchModal
        openDialog={searchDialog}
        handleCancel={() => setSearchDialog(false)}
      />

      <Layout.Sider
        style={siderStyles}
        width={276}
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed, type) => {
          if (type === "clickTrigger") {
            setSiderCollapsed(collapsed);
          }
        }}
        collapsedWidth={80}
        breakpoint="lg"
        trigger={null}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <Flex style={{ padding: "15px" }} justify="space-between">
              <Badge>
                <Space align="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "219px",
                    }}
                  >
                    <Avatar
                      shape="circle"
                      size="large"
                      src={avatarsrc ?? imageurl}
                      style={{
                        backgroundColor:
                          avatarsrc || imageurl
                            ? "transparent"
                            : currentTeam?.metadata?.avatar?.color,
                        color: getContrastColor(
                          currentTeam?.metadata?.avatar?.color ?? "#000"
                        ),
                      }}
                    >
                      {currentTeam?.name?.charAt(0)}
                    </Avatar>

                    {!siderCollapsed && (
                      <Typography.Text
                        ellipsis={true}
                        style={{ maxWidth: "170px", paddingLeft: "8px" }}
                      >
                        {currentTeam?.name}
                      </Typography.Text>
                    )}
                  </div>
                </Space>
              </Badge>
            </Flex>

            {renderMenu()}
          </div>
          <div>

            <Menu
              style={{ marginBottom: "20px", background: "transparent", borderRight: "none" }}
              mode="vertical"
              expandIcon={<></>}
              items={bottomMenu}
              selectedKeys={selectedKeys}
              subMenuCloseDelay={0.2}
            />

          </div>
        </div>
      </Layout.Sider>
      <AccountShow
        openModal={openAccountModal}
        setOpenModal={() => setOpenAccountModal(false)}
      />
    </>
  );
};
