import { PlusOutlined } from "@ant-design/icons";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../utility/formatNumber";
import InvoiceProductRow from "./InvoiceProductRow";
import "./InvoiceTable.css";

type Props = {
  initialValues?;
  onProductsChange?: (any) => void;
  onTotalChange?: (any) => void;
};

const InvoiceTable = ({
  initialValues = [],
  onProductsChange,
  onTotalChange,
}: Props) => {
  const [products, setProducts] = useState(
    initialValues.length > 0
      ? initialValues
      : [
          {
            id: "product-1",
            position: 1,
            type: "item",
            name: "",
            description: "",
            reference_id: null,
            price_per_item: 0,
            variant: null,
            tax_rate: 19,
            unit: "piece",
            discount: 0,
            quantity: 1,
          },
        ]
  );

  const [totals, setTotals] = useState<any>();
  const [idCounter, setIdCounter] = useState(products.length + 1);

  useEffect(() => {
    const mappedProducts = products.map((product) => {
      const { position, ...productData } = product;
      return productData;
    });

    const newTotals = calculateTotals();
    setTotals(newTotals);

    if (onProductsChange) {
      onProductsChange(mappedProducts);
    }

    if (onTotalChange) {
      onTotalChange(newTotals);
    }
  }, [products]);

  const handleSaveRow = (updatedProduct, index) => {
    setProducts((prev) =>
      prev.map((product, idx) => {
        if (idx === index) {
          return { ...updatedProduct };
        }
        return product;
      })
    );
  };
  const handleDeleteRow = (index) => {
    setProducts((prev) =>
      prev
        .filter((_, idx) => idx !== index - 1)
        .map((product, newIndex) => ({ ...product, position: newIndex + 1 }))
    );
  };

  const handleAddRow = () => {
    setProducts((prev) => [
      ...prev,
      {
        id: `product-${idCounter}`,
        position: prev.length + 1,
        type: "item",
        name: "",
        description: "",
        reference_id: null,
        price_per_item: 0,
        variant: null,
        tax_rate: 19,
        unit: "piece",
        discount: 0,
        quantity: 1,
      },
    ]);
    setIdCounter(idCounter + 1);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setProducts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update positions
        return newItems.map((item: any, index) => ({
          ...item,
          position: index + 1,
        }));
      });
    }
  };

  const calculateTotals = () => {
    const subtotal19 = products
      .filter((product) => product.tax_rate === 19)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );

    const subtotal7 = products
      .filter((product) => product.tax_rate === 7)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );
    const subtotal0 = products
      .filter((product) => product.tax_rate === 0)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );

    const tax19 = subtotal19 * 0.19;
    const tax7 = subtotal7 * 0.07;
    const tax0 = subtotal0;

    return {
      subtotal19: subtotal19.toFixed(2),
      subtotal7: subtotal7.toFixed(2),
      subtotal0: subtotal0.toFixed(2),
      tax19: tax19.toFixed(2) as any,
      tax7: tax7.toFixed(2) as any,
      tax0: tax0.toFixed(2) as any,
      total: (subtotal19 + tax19 + subtotal7 + tax7 + subtotal0).toFixed(2),
    };
  };

  return (
    <div className="invoice-table-container">
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={products.map((product) => product.id)}
          strategy={verticalListSortingStrategy}
        >
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Pos.</th>
                <th>Produkt / Service</th>
                <th>Menge</th>
                <th>Einheit</th>
                <th>Preis (€)</th>
                <th>Steuer</th>
                <th>Rabatt (%)</th>
                <th>Gesamt</th>
                <th></th>
              </tr>
            </thead>
            {products.map((product, index) => (
              <InvoiceProductRow
                key={product.id}
                id={product.id}
                data={product}
                isEditing={true}
                onSave={(val) => handleSaveRow(val, index)}
                onDelete={handleDeleteRow}
                order={index + 1}
              />
            ))}
          </table>
        </SortableContext>
      </DndContext>
      <Button
        type="dashed"
        onClick={handleAddRow}
        icon={<PlusOutlined />}
        className="add-row-button"
      >
        Neue Position
      </Button>
      <div className="totals">
        {totals?.subtotal19 != 0 && (
          <div>
            <span>Zwischensumme (19%): </span>
            <span>{totals?.subtotal19} €</span>
          </div>
        )}
        {totals?.subtotal7 != 0 && (
          <div>
            <span>Zwischensumme (7%): </span>
            <span>{formatNumber(totals?.subtotal7)} €</span>
          </div>
        )}
        {totals?.subtotal0 != 0 && (
          <div>
            <span>Zwischensumme (0%): </span>
            <span>{formatNumber(totals?.subtotal0)} €</span>
          </div>
        )}
        {totals?.tax19 != 0 && (
          <div>
            <span>Steuern (19%): </span>
            <span>{formatNumber(totals?.tax19)} €</span>
          </div>
        )}
        {totals?.tax7 != 0 && (
          <div>
            <span>Steuern (7%): </span>
            <span>{formatNumber(totals?.tax7)} €</span>
          </div>
        )}
        <div className="total-amount">
          <span>Gesamtbetrag: </span>
          <span>{formatNumber(totals?.total)} €</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
